const dev = {
  STRIPE_KEY: "pk_test_cNH6MMlR7S4zrWiypcNgxWGd00pMVAngsu",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-api-dev-attachmentsbucket-1iuafkl506kn8"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://uocrlzc5v4.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_4x7bcSuyG",
    APP_CLIENT_ID: "30igj4rhel7g1iga6mag3ftok0",
    IDENTITY_POOL_ID: "us-east-1:0d724728-aace-4971-8ca1-8cad5dcf4aeb"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_cNH6MMlR7S4zrWiypcNgxWGd00pMVAngsu",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-api-prod-attachmentsbucket-aqa1eoj7ragq"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://bqd6u0l76e.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Y1mswRYFY",
    APP_CLIENT_ID: "3tc8asj2069e5pi26lc7mdl1m3",
    IDENTITY_POOL_ID: "us-east-1:9271aa6d-d886-45d9-9ed4-124f057f8b6d"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
